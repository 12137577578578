import React, { useState } from "react";
import { Layout, Row, Col, Modal, Typography, Card } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
// import "..assets/style/aboutus.css";
import HomeHeader from "../components/HomeHeader";
import HomePageFooter from "../components/HomePageFooter";
import SignIn from "./SignIn";
import "../assets/styles/signinModal.css";
import "../assets/styles/homepagefooter.css";
import "../assets/styles/media.css"
import mps from "../assets/images/most_promising_startup.svg"
import wiii from "../assets/images/why_invest_in_India.svg"

const { Title, Text } = Typography;

const Media = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const blogs = [
    {
      id: 1,
      title: "20 Most Promising Startups To Watch – 2023",
      date: "March 30, 2023",
      author: "Akshay Kunkulol",
      content: "“As India is poised to become 30+ trillion dollars economy, we would like everyone to have piece of that pie.” – Akshay Kunkulol, founder & CEO of InfinityPool Finnotech quoted watch.",
      image: mps,
      href: "https://businessconnectindia.in/infinitypool-finnotech-private-limited/"
    },
    {
      id: 2,
      title: "Why Invest in India?",
      date: "January 09, 2023",
      author: "Akshay Kunkulol",
      content: "Change is the only constant and with the recent pandemic having thrown our lives out of gear, the impact on different industries in India has been varied, but what.",
      image: wiii,
      href: "http://akshaykunkulol.me/blogs/why-invest-in-india/"
    },
  ];

  return (
    <>
      <Layout className="layout-dashboard">
        <HomeHeader showModal={showModal} />
        <Content>
            <div className="blogs-container">
              {blogs.map((blog, index) => (
                <Row gutter={[16, 18]} className="blog-row" key={blog.id}>
                  <Col
                    xs={24}
                    md={8}
                    className={`image-col ${
                      index % 2 === 0 ? "order-1-md" : "order-2-md"
                    }`}
                  >
                    <img
                      src={blog.image}
                      alt={blog.title}
                      className="blog-image"
                    />
                    <div className="blog-meta">
                      <span className="blog-author">{blog.author}</span>
                      <span className="blog-date">{blog.date}</span>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    md={16}
                    className={`text-col ${
                      index % 2 === 0 ? "order-2-md" : "order-1-md"
                    }`}
                  >
                    <div className="blog-text">
                      <h4>{blog.title}</h4>
                      <p>{blog.content}</p>
                      <a href={blog.href} target="_blank" className="read-more">
                        Read More
                      </a>
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
        </Content>
      </Layout>
      <HomePageFooter />
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="full-screen-modal"
        width="100%"
        style={{ top: 0, padding: 0 }}
        bodyStyle={{ height: "100vh", padding: 0, margin: 0 }}
      >
        <SignIn onClose={handleCancel} />
      </Modal>
    </>
  );
};

export default Media;
