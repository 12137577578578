import React, { useState } from "react";
import { Layout, Row, Col, Modal, Typography, Card, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import "antd/dist/antd.css";
// import "..assets/style/aboutus.css";
import HomeHeader from "../components/HomeHeader";
import HomePageFooter from "../components/HomePageFooter";
import SignIn from "./SignIn";
import "../assets/styles/signinModal.css";
import "../assets/styles/homepagefooter.css";
import "../assets/styles/contactus.css";
import contactus from "../assets/images/contactus.svg";

const { Title, Text } = Typography;

const Contactus = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const reasons = [
    "Intellectual Challenge",
    "Influence and Shape",
    "Informal and Flexible Culture",
    "Innovation Collaboration Transparency",
    "Flat Hierarchy",
    "Sense of Purpose",
  ];

  const jobList = [
    { title: "AI Software Developer", type: "Full-Time | Remote" },
    { title: "Equity and Derivatives Trader", type: "Intern | Remote" },
    { title: "Creative Content Writer", type: "Full-Time | Remote" },
    { title: "Compliance Officer", type: "Full-Time | Remote" },
    { title: "Data Analyst", type: "Full-Time | Remote" },
    { title: "Marketing Specialist", type: "Full-Time | Remote" },
    { title: "Project Manager", type: "Full-Time | Remote" },
    { title: "UX/UI Designer", type: "Full-Time | Remote" },
  ];

  return (
    <>
      <Layout className="layout-dashboard">
        <HomeHeader showModal={showModal} />
        <Content style={{ padding: "20px" }}>
        <Row className="container">
          <Col xs={24} sm={12} md={8} className="character">
            <img src={contactus} alt="Shankh's Products" />
          </Col>
          <Col xs={24} sm={12} md={16} className="info">
            <div className="message">
              <p>
                If you are interested in Shankh's Products, please fill in this form
                and appropriate team member will get back to you within 1
                business day.
              </p>
              <Button href="https://forms.gle/GdebQ7w3LwyPKSSx7" type="primary" target="_blank"
                                        className="apply-btn ant-btn-primary fill-form">FILL FORM</Button>
            </div>
          </Col>
        </Row>
        <Col className="contact-container">
          <Card>
            <div className="contact">
              <p>
                For other queries including participating in our beta programs,
              </p>
              <a href="mailto:contact@shankh.tech">contact@shankh.tech</a>
            </div>
          </Card>
        </Col>
        </Content>
      </Layout>
      <HomePageFooter />
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="full-screen-modal"
        width="100%"
        style={{ top: 0, padding: 0 }}
        bodyStyle={{ height: "100vh", padding: 0, margin: 0 }}>
        <SignIn onClose={handleCancel} />
      </Modal>
    </>
  );
};

export default Contactus;
